<template>
  <div class="banner mb-5 mt-5 pt-md-4">
    <div class="container">
      <div class="p-3 px-md-0 py-md-5 d-flex flex-column flex-sm-row gap-4">
        <AvatarProfile/>
        <div class="m-auto order-sm-1 col-md-6">
          <h1 class="fw-bolder green-color">
            Développeur Fullstack
            <span class="text-white">.</span>
          </h1>
          <h2 class="fw-bolder text-white mb-3">
            Codant avec
            <span class="typed-text">{{ typeValue }}</span>
            <span class="blinking-cursor">|</span>
            <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
          </h2>
          <p class="text-white mb-3">Salut, je m'appelle Mickaël, mais mes amis m'appellent Miki. Je suis un développeur Fullstack passionné, vivant à Montréal 🇨🇦 !</p>
          <div class="d-flex gap-3 mb-5">
            <a href="https://www.linkedin.com/in/mickael-riss/" target="_blank" alt="Linkedin" title="Linkedin">
              <svg xmlns="http://www.w3.org/2000/svg" style="fill:white" width="28" height="28" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
            </a>
            <a href="https://github.com/MendosDV" target="_blank" alt="Github" title="Github">
              <svg xmlns="http://www.w3.org/2000/svg" style="fill:white" width="28" height="28" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
            </a>
          </div>
          <div class="mt-2 d-flex justify-content-center align-items-center gap-5 green-color small-text mb-4 mb-md-5">
            <p>
              Je m'efforce de créer des expériences utilisateur robustes en combinant mes compétences en développement frontend et backend pour fournir des solutions fonctionnelles.
            </p>
            <p>Je suis animé par une passion pour la découverte et l'innovation, toujours ouvert à saisir des opportunités diverses dans le domaine du développement.</p>
          </div>
          <TechStack />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarProfile from './AvatarProfile.vue'
import TechStack from './TechStack.vue'

export default {
  name: 'BannerApp',
  components: { AvatarProfile, TechStack },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
  data() {
    return {
      typeValue: "",
      typeStatus: false,
      displayTextArray: ["Ruby on Rails", "Vue.js", "Node.js", "React.js"],
      typingSpeed: 100,
      erasingSpeed: 50,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
    }
  },
  methods: {
    typeText() {
      if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.displayTextArrayIndex += 1;
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background-color: #446DF6;

  .small-text {
    font-size: 12px;
  }
}

h2 {
  font-size: 1.2rem;
  span.typed-text {
    color: #CCF281;
  }
}

.blinking-cursor {
  font-size: 1.2rem;
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
</style>
