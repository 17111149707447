<template>
  <div class="d-flex justify-content-center align-items-center mb-5 py-5 ">
    <div class="w-75 d-flex justify-content-center gap-5 row align-items-center ">
      <div class="col-md-5">
        <img src="https://a.cdn-hotels.com/gdcs/production175/d204/32b38e20-cb15-11e8-a1a9-0242ac110002.jpg" alt="Miki" class="rounded img-fluid">
      </div>
      <div class="col-md-5">
        <h5 class="blue-color bolder">Qui suis-je ?</h5>
        <h4 class="mb-4">Basé à Montréal et disponible pour travailler ensemble! 👨‍💻</h4>
        <p class="small-text">Actuellement, je réalise des missions en freelance et travaille sur des projets personnels en utilisant des technologies qui me passionnent, telles que Ruby on Rails, Node.js, React.js et Vue.js. Je suis disponible, alors n'hésitez pas à me contacter !</p>
        <a href="/website-cv.pdf" download="Mickael RISS CV" class="btn blue-background-color text-white">
          <span class="me-2">
            CV
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download fw-bolder">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
          </svg>
        </a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutApp'
}
</script>

<style lang="scss" scoped>
  .small-text {
    font-size: 12px;
  }

  .btn {
    transition: all 0.3s;
    width: 44%;
  }
  .btn:hover {
    transform: scale(1.02);
    background-color: #CCF281 !important;
    color: #446DF6 !important;
  }

  .btn:active {
    border: none;
  }

  @media (min-width: 300px) {
    .btn {
      font-size: 0.8rem;
      width: 35%;
    }
  }

  @media (min-width: 768px) {
    .btn {
      font-size: 0.8rem;
      width: 40%;
    }
  }

  @media (min-width: 1024px) {
    .btn {
      width: 30%;
    }
  }


  @media (min-width: 2000px) {
    .btn {
      width: 10%;
    }
  }

  img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>
